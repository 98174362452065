/* @jsx jsx */
import React from 'react'
import {jsx} from '@emotion/react'
import {
  productWrapperCss,
  descriptionCss,
  imagesCss,
  titleCss,
} from './styles'


import Heading1 from '@atoms/headings/heading1/heading1'
import ProductImageCarousel from '@molecules/ProductImageCarousel/index'
import ProductForm from '@molecules/ProductForm/index'

const ShopifyProduct = product => {
  const {title, description, images} = product
  return (
    <>
      <div css={productWrapperCss}>
        <div>
          {title && <Heading1 css={titleCss}>{title}</Heading1>}

          {description && (
            <div css={descriptionCss}>
              {description}
            </div>
          )}

          <ProductForm product={product} />
        </div>
        <div css={imagesCss}>
          <ProductImageCarousel images={images.map(image => image.originalSrc)}/>
        </div>
      </div>
    </>
  )
}

export default ShopifyProduct
