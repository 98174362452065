import {css} from '@emotion/react'
import {colors, fonts, breakpoints, unitConverter} from '@utils/base'

export const subscribeCss = css`
  color: ${colors.text};
  font-family: ${fonts.signo};
  line-height: 1.4;
  margin: ${unitConverter('40px 0 20px')};

  div {
    position: relative;
    top: 7px;
    left: 10px;
  }
`;

export const sectionCss = css`
  margin-top: ${unitConverter('40px')};
`
