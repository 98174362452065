import {css} from '@emotion/react'
import {colors, unitConverter} from '@utils/base'

export const imageWrapperCss = css`
  max-height: 295px;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 300px;
    width: auto;
    display: block;
    margin: 0 auto;
  }
`;

export const thumbnailWrapperCss = css`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center
  padding-top: ${unitConverter('10px')};
  list-style: outside none;
  line-height: 0;
  justify-content: flex-start;
  margin-top: 10px;

  li {
    max-width: calc(25% - 10px);
    width: 100%;
    cursor: pointer;
    border: ${unitConverter('1px')} solid ${colors.darkGray};
    overflow: hidden;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${colors.darkGray};
    margin: 5px;

    &.current-product-image {
      border: ${unitConverter('1px')} solid ${colors.white};
    }

    img {
      max-height: 100px;
      max-width: 100%;
      display:  block;
      margin: 0 auto;
    }
  }
`;
