/* @jsx jsx */
import React, {useState} from 'react'
import {jsx} from '@emotion/react'
import Image from '@atoms/image'
import {imageWrapperCss, thumbnailWrapperCss} from './styles'

const ProductImageCarousel = ({images}) => {
  if (images.length === 0) return <></>;
  const [currentImage, setCurrentImage] = useState(images[0]);

  const handleClick = (e) => {
    let imgSrc = ''
    const whereDidTheyClick = e.target.tagName

    document.getElementsByClassName('current-product-image')[0].classList.remove('current-product-image')

    if (whereDidTheyClick === 'IMG') {
      imgSrc = e.target.src
      e.target.parentNode.classList.add('current-product-image')
    } else {
      // they clicked the LI instead.
      imgSrc = e.target.childNodes[0].src
      e.target.classList.add('current-product-image')
    }

    setCurrentImage(imgSrc)
  }

  return (
    <div>
      <div css={imageWrapperCss}>
        <Image src={currentImage} alt="product image" maxWidth={'100%'}/>
      </div>
      <ul css={thumbnailWrapperCss}>
        {images.map((image, index) => <li className={index === 0 ? 'current-product-image' : ''} onClick={e => handleClick(e)} key={`thumbnail-${index}`}><Image src={image} alt={'product thumbnail'} maxWidth={'50px'} /></li>)}
      </ul>
    </div>
  )
}

export default ProductImageCarousel
