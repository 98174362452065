/* @jsx jsx */
import React from 'react'
import {css, jsx} from '@emotion/react'
import {colors, fonts, transitions, fontWeights, unitConverter} from '@utils/base'
import searchBg from '@img/search-bg.jpg'

export const productFormCss = css`
  color: ${colors.lightGrayHover};
  font-family: ${fonts.signo};

  > div {
    margin-top: ${unitConverter('20px')};
    font-size: 1em;
    display: flex;
    align-items: center;

    label {
      margin-right: 20px;
      width: 100%;
      max-width: 100px;
      color: ${colors.secondaryText};
    }

    input, select {
      padding: ${unitConverter('5px 10px')};
      font-family: ${fonts.signo};
      border: 1px solid ${colors.lightGray};
      background: black url(${searchBg}) 0 0 repeat-x;
      color: ${colors.white};
      font-size: .8em;
      margin-right: ${unitConverter('10px')};
      outline: none;
      font-size: .9em;

      &#quantity {
        width: 200px;
      }
    }

    select {
      padding: ${unitConverter('5px 10px')};
      appearance: none;
      border-radius: 0;
      padding-right: 40px;
      position: relative;
      width: 100%;
    }
  }
`

export const selectWrapperCss = css`
  position: relative;
  width: 100%;
  max-width: 200px;
  &:hover {
    svg {
      fill: ${colors.lightGrayHover};
    }
  }

  svg {
    transition: all ${transitions.fast} ease-in-out;
    fill: ${colors.lightGray};
    position: absolute;
    right:${unitConverter('7px')};
    top: ${unitConverter('7px')};
    pointer-events: none;
  }

`

export const addToCartWrapperCss = css`
  padding-top: ${unitConverter('20px')};
  display: flex;

  h3 {
    margin-right: ${unitConverter('20px')};
    font-size: 2em;
    font-family: ${fonts.signo};
    font-weight: 100;
  }

  button {
    padding: ${unitConverter('10px 20px')};
    border: none;
    border-radius: ${unitConverter('5px')};
    background-color: ${colors.lightGray};
    color: ${colors.darkGray};
    border: ${unitConverter('1px')} solid ${colors.darkGray};
    transition: all ${transitions.fast} ease-in-out;
    outline: none;
    font-family: ${fonts.signo};
    font-size: .9em;
    cursor: pointer;
    font-weight: ${fontWeights.bold};
    text-transform: uppercase;

    &:hover {
      background-color: ${colors.lightGrayHover};
    }
  }
`
