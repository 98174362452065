/* @jsx jsx */
import React, {useEffect, useState} from 'react'
import {jsx} from '@emotion/react'
import {graphql} from 'gatsby'
import Layout from '@containers/layout'
import GraphQLErrorList from '@components/graphql-error-list'
import Seo from '@components/seo'
import ContentWrapper from '@molecules/contentWrapper'
import Header from '@organisms/header'
import YoutubeSubscribeButton from '@molecules/youtubeSubscribe'
import ShopifyProduct from "@components/organisms/ShopifyProduct";
import {subscribeCss, sectionCss} from './styles'
import HeroImage from "@components/organisms/HeroImage";
import Sections from "@components/organisms/Sections";

const get = require('lodash.get');
const ProductPage = ({data, errors}) => {

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const {site, product, page} = data
  const {seo, global, header, footer} = site
  const {headerImage, sections, _rawSections} = page

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout footerSettings={footer} bgImage={get(global.backgroundImage, 'asset.fluid.src')}>
      <Seo
        title={product.title}
        description={get(product, 'description', '')}
        keywords={seo.keywords}
        ogType='website'
        image={get(headerImage, 'asset.fluid.src')}
      />
      <ContentWrapper>
        <Header title={seo.title} {...header} showSearch={false} logoWidth={400} />

        {headerImage && (<HeroImage src={headerImage} />)}
        <ShopifyProduct {...product} />
        <Sections sections={sections} rawSections={_rawSections} css={sectionCss} />

        <p css={subscribeCss}>
          Don't forget to SUBSCRIBE! <YoutubeSubscribeButton channel="UCD8d3FGC907iS1qiMF0ccxA"/>
        </p>
      </ContentWrapper>
    </Layout>
  )
}

export default ProductPage

export const query = graphql`
  query ProductPageQuery($slug: String!){
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      seo {
        title
        description
        keywords
      }
      header {
        tagline
        logo {
          asset {
            _id
            fluid {
              base64
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
      global {
        backgroundImage {
          asset {
            id
            _id
            fluid {
              base64
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
      footer {
        address
        tagline
        listOfLinks {
          __typename
          ... on SanityLink {
            linkText
            linkUrl
            _type
          }
        }
      }
    }
    product: shopifyProduct(handle: { eq: $slug }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
      }
    }
    page: sanityProduct(handle: {eq: $slug}) {
      _rawSections(resolveReferences: {maxDepth: 100})
      headerImage {
        asset {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
          _id
        }
      }
      sections {
      ... on SanityPageColumns {
        _key
        _type
        columns {
          title
          width
          padding
          _rawSections
          sections {
            ... on SanityPageColumns {
                _key
                _type
                _rawColumns(resolveReferences: {maxDepth: 100})
              }
              ... on SanityPageContent {
                _key
                _type
                _rawBody(resolveReferences: {maxDepth: 100})
              }
              ... on SanityStarBarDemos {
                _key
                _type
                _rawBody(resolveReferences: {maxDepth: 100})
                selectLabel
              }
              ... on SanityGooglePlayButton {
                _key
                _type
                appId
              }
            }
          }
          _rawColumns(resolveReferences: {maxDepth: 100})
        }
        ... on SanityPageContent {
          _key
          _type
          _rawBody(resolveReferences: {maxDepth: 100})
        }
        ... on SanityStarBarDemos {
          _key
          _type
          _rawBody(resolveReferences: {maxDepth: 100})
          selectLabel
        }
        ... on SanityGooglePlayButton {
          _key
          _type
          appId
        }
      }
    }
  }
`
