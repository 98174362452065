import {css} from '@emotion/react'
import {colors, fonts, fontWeights, breakpoints, unitConverter} from '@utils/base'

export const descriptionCss = css`
  color: ${colors.text};
  margin-bottom: ${unitConverter('40px')};
  font-family: ${fonts.signo};
  opacity: .8;
  line-height: 1.8;
  font-weight: 100;

  p {
  color: red;
    font-weight: ${fontWeights.light};
  }
`;

export const titleCss = css`
  margin-bottom: ${unitConverter('40px')};
`;

export const productWrapperCss = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    width: 50%;

    ${breakpoints.tabletLandscape} {
      width: 100%;
      padding: 0;
      margin-bottom: ${unitConverter('40px')};

      ${breakpoints.mobile} {
        padding: 0px;
      }

      &:first-of-type {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        h1 {
          width: 100%;
        }

        > div {
          width: 50%;

          ${breakpoints.tabletPortrait} {
            width: 100%;
            margin-bottom: ${unitConverter('40px')} !important;
            padding-right: 0 !important;
          }

          &:nth-of-type(1) {
            padding-right: ${unitConverter('60px')};
            margin-bottom: 0;
          }

          &:nth-of-type(2) {
            div {
              &:first-of-type {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
`;

export const imagesCss = css`
  padding-left: ${unitConverter('20px')};
`;
